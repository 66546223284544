body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
:root{
  --primarycolor:#2767A1;
  --secondrycolor:#D81C20;
  --textcolor:#222222;
  --lighbgcolor:rgb(255 255 255 / 30%);
  --warningcolor:#FFC516;
  --dangercolor:#DC3545;
  --successcolor:#198754;
  --lightgrey:#f7f7f7;
  --whitebg:#ffffff;
  --transition: all ease .5s;
  --transitionTwo: all ease .9s;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-primary{
  background: var(--primarycolor) !important;
  border-color: var(--primarycolor) !important;
}
.btn-primary:hover{
  /* background: var(--secondrycolor) !important; */
  border-color: var(--secondrycolor) !important;
}
.bg-main {
  background-color: var(--primarycolor) !important;
}
.input-group-append .btn {
  border-top-left-radius: 0rem !important;
  border-bottom-left-radius: 0rem !important;

}
.input-group-append .btnshow {
  border-top-left-radius: 0.35rem !important;
  border-bottom-left-radius: 0.35rem !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;

}
table{
  font-size: 13px;
}
thead th{
  background-color: #f8f9fc !important;
}
h3{
  font-size: 20px !important;
  color: #0a266c !important;
}
.metdata-form label{
  font-weight: 600;
  margin-bottom: 6px;
}

.btn.disabled, .btn:disabled, fieldset:disabled .btn{
  background: #00000038 !important;
  border-color: #00000000 !important;
  color: #00000036 !important;
}
.css-9f93hb.ps-menu-root .ps-menu-icon{
  color: var(--primarycolor) !important;
}
.btn{
  position: relative;
  z-index: 1;
}
.btn:hover{
  border: 1px solid var(--secondrycolor) !important;
}
.btn:before {
  position: absolute;
  top: 0;
  left: 0%;
  content: "";
  width: 50.5%;
  height: 0%;
  z-index: -1;
  transition: var(--transition);
}
.btn:before {
  background-color: var(--secondrycolor);
}
.btn:hover:before{
  height: 100%;
  visibility: visible;
  opacity: 1;
}

.btn:after{
  position: absolute;
  bottom: 0;
  right: 0%;
  content: "";
  width: 50.5%;
  height: 0%;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  transition: var(--transition);
}
.btn:after {
  background-color: var(--secondrycolor);
  border:0px !important;
}
.btn:hover:after{
  height: 100%;
  visibility: visible;
  opacity: 1;
}
.microscopeicon{
  width: 50px;
}
.page-item.active a{
  color: #ffffff !important;
}
.tableicon svg{
  background: transparent !important;
  color: #ffffff !important;
  padding: 0 !important;
}



.loader-wrapper {
  --line-width: 5px;
  --curtain-color: #f1faee;
  --outer-line-color: var(--secondrycolor);
  --middle-line-color: #457b9d;
  --inner-line-color: #1d3557;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
.loader {
  display: block;
  position: relative;
  top: 50%;
  left: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: var(--line-width) solid transparent;
  border-top-color: var(--outer-line-color);
  border-radius: 100%;
  animation: spin 2s linear infinite;
  z-index: 1001;
}
.loader:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  border: var(--line-width) solid transparent;
  border-top-color: var(--inner-line-color);
  border-radius: 100%;
  animation: spin 3s linear infinite;
}
.loader:after {
  content: "";
  position: absolute;
  top: 14px;
  left: 14px;
  right: 14px;
  bottom: 14px;
  border: var(--line-width) solid transparent;
  border-top-color: var(--middle-line-color);
  border-radius: 100%;
  animation: spin 1.5s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-wrapper .loader-section {
  position: fixed;
  top: 0;
  background: var(--lightgrey);
  width: 51%;
  height: 100%;
  z-index: 1000;
}
.loader-wrapper .loader-section.section-left {
  left: 0;
}
.loader-wrapper .loader-section.section-right {
  right: 0;
}
.loaded .loader-wrapper .loader-section.section-left {
  transform: translateX(-100%);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.loaded .loader-wrapper .loader-section.section-right {
  transform: translateX(100%);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.loaded .loader {
  opacity: 0;
  transition: all 0.3s ease-out;
}
.loaded .loader-wrapper {
  visibility: hidden;
  transform: translateY(-100%);
  transition: all 0.3s 1s ease-out;
}
.table-bordered>:not(caption)>*{
  border-width: var(--bs-border-width) !important;
}
.investigationtablebottom{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.couponwrap{
  width: 60%;
  justify-content: end;
}
.couponwrap button{
  width: 200px;
}
.border-left, h3{
  border-width: 0 0 0 5px;
  border-style: solid;
  border-color: var(--secondrycolor);
  padding-left: 10px;
}
.magnifineglass {
  position: absolute;
  right: 25px;
  top: 15px;
}